import { Facebook, WhatsApp } from '@mui/icons-material';
import './App.css';
import './main.css'
import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Link } from 'react-router-dom';

const App = () => {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing after it's visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the section is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) observer.unobserve(sectionRef.current);
    };
  }, []);
  

  return (
    <BrowserRouter>
    <div className="flex flex-col ">
      <div className='hero-text text-center md:text-left flex p-4'>
        <div className='flex flex-col space-y-4 md:w-1/2 p-6'>
        <p className='font-bold text-4xl md:text-5xl text-white'>Unleash Your <span className='text-purple-600'>Earning</span> Potential</p>
        <p className='font-bold text-2xl text-purple-600'>Discover the Proven Business Model That Can Generate <span className='text-white'>100k - 300k</span> Monthly</p>
        <p className='font-bold text-white md:w-1/2'>Join Our Free Class Today to Learn How to Transform Your Passion into Profits!</p>
        <a href='https://wa.me/2349024483322'>

        <button  className='font-bold md:w-1/3 text-purple-600  hover:text-white p-2'><span>Join Now</span></button>
        </a>

        </div>
      <section>
        <div className='hidden md:flex'>

        <div class=" custom-shape-divider-bottom-1731808011">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
          </svg>
        </div>
        </div>

        <div class="hidden custom-shape-divider-bottom-1731809408">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
          </svg>
        </div>
      </section>
      </div>
      <section ref={sectionRef}
      className={`scroll-section ${isVisible ? "visible" : ""} p-5`}>
        <div class="content text-center flex flex-col justify-center items-center">
    <h2 className='font-bold text-purple-800 text-3xl'>Transform Your Dreams Into Reality</h2>
    <p className='font-bold text-2xl text-gray-500'>Discover the steps to unlock a six-figure monthly income stream with our proven business model.</p>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-8 p-4">
      <div class="feature rounded-lg text-center space-y-3 bg-purple-200 flex flex-col p-4 scale">
        <p className='text-bold text-purple-500'>Step-by-Step Guidance</p>
        <p className='text-sm font-bold'>Learn the exact strategies to build and scale your affiliate marketing business.</p>
      </div>
      <div class="feature rounded-lg text-center space-y-3 bg-purple-200 flex flex-col p-4 scale">
        <p className='text-bold text-purple-500'>Expert Mentorship</p>
        <p className='text-sm font-bold'>Work alongside top People who've achieved the 100k-300k monthly benchmark.</p>
      </div>
      <div class="feature rounded-lg text-center space-y-3 bg-purple-200 flex flex-col p-4 scale">
        <p className='text-bold text-purple-500'>Proven System</p>
        <p className='text-sm font-bold'>Our methods are tested and designed to work—even if you're starting from scratch.</p>
      </div>
    </div>
    <a href='https://wa.me/2349024483322'>

    <button className='font-bold  text-purple-600  hover:text-white p-2'><span>Join Now</span></button>
    </a>
  </div>
      </section>
  
      <div className='hero-text2 flex justify-center items-center p-4 space-y-6'>
      <p className='font-bold text-4xl md:text-8xl text-white'>Start <span className='text-purple-600'>Now</span></p>
      <p className='font-bold text-4xl md:text-8xl text-white'>Earn <span className='text-purple-600'>Big</span></p>
      </div>
      
      <div className='bg-purple-400 text-center'>
    <p className='text-4xl font-bold text-purple-700 p-4'> Who can use this Business Model</p>
    <p classNam='font-bold text-sm '>I will be showing you how to work with this business model that will help you escape being broke and find financial freedom.</p>

    <p classNam='font-bold text-sm '>This business model does not work like a pyramid scheme and does not require you to bring referals to earn from them.</p>
  
    <p classNam='font-bold text-sm '>It does not matter if you not the tech-savvy type or do not have any experience running an online business</p>

    <p classNam='font-bold text-sm '>Are you a <span className='font-bold text-2xls'>student, a stay at home mom, or  a 9-5 worker</span> looking for a way to pay your bills and other necesities</p>

    <p>Then this business model is for you</p>
    <a href='https://wa.me/2349024483322'>
    <button className='font-bold 
       text-purple-600  hover:text-white p-2 m-6'><span>Join Now</span></button>
    </a>
  </div>
  <section>
      <div class="custom-shape-divider-bottom-1731878626 bg-purple-400">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
              <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
              <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
          </svg>
      </div>
      </section>
      <div className='testimonials p-6 flex flex-col justify-center items-center'>
        {
          //testimonials
        }
        <p className='p-3 text-3xl font-bold text-purple-700'>Testimonials</p>
        <p className='p-3 font-bold text-purple-700 p-4'>Testimonies from people who have used this business model</p>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-7 md:p-6'>
          <div className='k300'>
        <div className='scale rounded-lg bg-purple-400 p-4'>
          <p className='font-bold text-sm text-purple-900'>Guess who just got a brand new laptop worth 300k, a mic worth 30k, investing in my gadgets to take my affiliate marketing business to the next level. God bless the day i came across this businness.</p>
        </div>

          </div>
        <div className='k200'>
        <div className='scale rounded-lg bg-purple-400 p-4'>
          <p className='font-bold text-sm text-purple-900'>

          Over 200k made by busy mom in this present economy, i blessed the day i said yes to affiliate marketing. there's money online stop wasting your data crusing on the internet.
          </p>
        </div>
        </div>
        <div className='k320'>
        <div className='scale rounded-lg bg-purple-400 p-4'>
          <p className='font-bold text-sm text-purple-900'>

          Another phone added with the money i made from affiliate marketing, time to go harder. Affiliate marketing is a babe, 320k well spent, thank you.
          </p>
        </div>
        </div>

        </div>
      </div>
      <div className='flex flex-col justify-center items-center p-6 bg-gray-900 text-center'>
        <p className='font-bold p-4 text-white text-center'>this site is not a part of Facebook.com or google.com or youtube.com, it is not an intellectual property of any of the listed, and is not in affiliation with any of the parties listed, it is a standalone site </p>
        <p className='font-bold p-4 text-white text-center'>Copyright 2024 - All rights reserved</p>
        <a href='https://wa.me/2349024483322'>
        <button className='font-bold text-purple-200 hover:text-white p-2 m-6'><span>Join Now</span></button>
        </a>
        <div className='text-white space-x-6 flex'>
        <a href='https://wa.me/2349024483322'>
          <WhatsApp fontSize='large'/>
        </a>
        <a href='https://www.facebook.com/profile.php?id=61569104292380&mibextid=ZbWKwL'>

        <Facebook fontSize='large'/>
        </a>
        </div>
      </div>
    </div>
    </BrowserRouter>
  );
}

export default App;
